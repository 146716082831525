body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 0;
  outline: 0;
  text-decoration: none;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
}

.sidebar {
  width: 320px;
  min-width: 320px;
  border-right: 1px solid #ccc;
  padding: 25px;

  .logo {
    margin-bottom: 25px;

    a {
      font-size: 30px;
    }
  }

  .description {
    font-size: 18px;
    line-height: 1.7;
    color: #999;
    margin-bottom: 25px;
  }

  .menu {
    ul {
      li {
        a {
          display: block;
          font-weight: 600;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #0099e5;
          }
        }
      }
    }
  }
}

.copied {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: var(--bgColor);
  color: var(--textColor);
  padding: 15px 25px;
  border-radius: 4px;
  font-size: 16px;
  z-index: 10;
}

.content {
  flex: 1;
  overflow: auto;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;

  .header {
    height: 60px;
    border-bottom: 1px solid #ccc;
    display: flex;
    position: sticky;
    justify-content: space-between;
    top: 0;
    background: #fff;
    align-items: center;

    .search {
      flex: 1;
      position: relative;

      .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      input {
        width: 100%;
        height: 100%;
        font-size: 16px;
        padding: 0 20px 0 60px;
      }
    }

    .back-btn {
      background: transparent;
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-left: 20px;
      color: #999;
      svg {
        display: block;
        margin-right: 10px;
      }
    }

    .download {
      padding-right: 25px;
      display: flex;
      align-items: center;

      .actions {
        display: flex;
        margin-right: 15px;

        a, button {
          height: 30px;
          width: 30px;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            height: 15px;
            width: 15px;
          }
        }
        select {
          margin-right: 7px;
          height: 30px;
          padding: 0 15px;
          background: #eee;
          border-radius: 4px;
          appearance: none;
          text-align: center;
          cursor: pointer;
        }
      }

      .selected {
        font-size: 14px;
        font-weight: 500;
        color: #999;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          display: block;
          margin-right: 7px;

          path {
            stroke: #999;
          }
        }
      }
    }
  }

  .brands {
    height: calc(100% - 60px);
    width: 100%;
    .brand {
      border: 1px solid #ddd;
      background: #fff;
      padding: 15px;
      margin: 10px 15px;

      h5 {
        font-size: 18px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      &-colors {
        display: flex;

        span {
          cursor: pointer;
          background: var(--bgColor);
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: var(--textColor);
          text-indent: -9999px;
          width: 70px;
        }
      }

      &.selected {
        border-color: red;

        .brand-colors {
          span {
            flex: 1;
            text-indent: 0;
          }
        }
      }
    }
  }
}

.about-modal {
  max-width: 720px;
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(#000, .1);
  padding: 50px;
  position: relative;

  .modal-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    background: #ddd;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  h3 {
    font-size: 26px;
    margin-bottom: 25px;
  }

  p {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
  }
}

.about-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(#fff, .8);
}

.error {
  background: #c8105b;
  padding: 20px;
  font-size: 18px;
  letter-spacing: .3px;
  margin: 25px;
  color: #fff;
  text-align: center;
}

@media(max-width: 1000px){
  #root {
    flex-direction: column;
    .sidebar {
      width: 100%;
      min-width: 100%;
      border-right: none;
      border-bottom: 1px solid #ccc;
      padding: 15px;
      .logo {
        margin-bottom: 15px;
      }
      .description {
        font-size: 15px;
      }
    }
    .content {
      .header {
        height: 50px;
        .download {
          display: none;
        }
      }
      .brands .brand.selected .brand-colors span {
        text-indent: -9999px;
      }
    }
  }
}